import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import settings from '../../../settings';
const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const volumecontrol = `${settings.IMAGES_BASE_URL}/images/assets/bmw-volume.png`;
class TopBannerIndy extends React.Component {
  state = {
    width: '',
    volume: false
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    })
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  scrollToRef = () => {
    window.scrollTo({
      top: 1000, 
      left: 0, 
      behavior: 'smooth' 
    });
  };

  volumeOn = () => {
    this.setState({
      volume: !this.state.volume
    })
  }

  render() {
    const { width } = this.state;
    const isMobile = width < 1200;
    const isDesktop = width >= 1200;

    return (
      <>
        {isMobile && (
          <section className="top__banner__indy__mobile">
            <LazyLoadImage
              effect="blur"
              src={this.props.mobileImage}
              alt={this.props.header}
            />
            <div className="container">
              <div className="top__banner__text">
                {this.props.header && <h1>{this.props.header}</h1>}
                <h2>
                  {this.props.subheader && <span>{this.props.subheader}</span>}
                  {this.props.subheader && <br />}
                  {this.props.pricing && <span>{this.props.pricing}</span>}
                </h2>
                {this.props.text && <p>{this.props.text}</p>}
                {this.props.secondText && <p className="second__text">{this.props.secondText}</p>}
              </div>
              <div className="buttons">
                {this.props.buttonText && (
                  <a
                    href={this.props.butttonURL}
                    starget="_blank"
                    rel="noopener noreferrer"
                    aria-label={this.props.ariaLabel}
                  >
                    <button className="btn btn-blue">{this.props.buttonText}</button>
                  </a>
                )}
                {this.props.agendaPopup && <div>{this.props.agendaPopup}</div>}
              </div>
            </div>
          </section>
        )}
        {isDesktop && (
          <section className=" video-background top__banner__indy">
            <video
              playsInline="playsinline"
              autoPlay="autoplay"
              muted={this.state.volume ? "" : "muted"}
              loop="loop"
              poster={this.props.poster}
            >
              <source src={this.props.url} type="video/mp4" />
              <track type="text/vtt" src={this.props.captions} label="English" kind="captions" default />
            </video>
            <h1 className="video-background__header-text" aria-label='video-title'>{this.props.headerTitle}</h1>
            <button className="arrow" aria-label='arrow-btn' onClick={this.props.onArrowClick || this.scrollToRef} onKeyDown={this.scrollToRef}>
              <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
            </button>
            {!this.props.noAudio && <button className="volume__control" onClick={this.volumeOn}><img src={volumecontrol} alt="Volume on" /> </button>}
          
            <div className="container">
              <div className="top__banner__content">
                <div className="top__banner__text">
                  {this.props.header && <h1>{this.props.header}</h1>}
                  <h2>
                    {this.props.subheader && <span>{this.props.subheader}</span>}
                    {this.props.subheader && <br />}
                    {this.props.pricing && <span>{this.props.pricing}</span>}
                  </h2>
                  {this.props.text && <p>{this.props.text}</p>}
                  {this.props.secondText && <p className="second__text">{this.props.secondText}</p>}
                </div>
                <div className="buttons">
                  {this.props.buttonText && (
                    <a
                      href={this.props.butttonURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={this.props.ariaLabel}
                    >
                      <button className="btn btn-blue">{this.props.buttonText}</button>
                    </a>
                  )}
                  {this.props.agendaPopup && <div>{this.props.agendaPopup}</div>}
                </div>
                {this.props.note && <p className="note">{this.props.note}</p>}
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default TopBannerIndy;
