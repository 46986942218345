import React, { useRef, useEffect, useState } from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tabs from "../components/tabs/Tabs";
import TabLink from "../components/tabs/TabLink";
import TabContent from "../components/tabs/TabContent";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-light-accordion/demo/css/index.css";
import SchedulePopup from "../components/schedulepopup";

import Layout from "../components/mschool/layout";
import SEO from "../components/seo";
import ProInstructors from "../components/mschool/proinstructors";
import TopBannerIndy from "../components/mschool/topbanner-indy";
import settings from "../../settings";
import BmwPcLocations from "../components/bmwGlance/bmwPcLocations";


// schools
const twodaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/two-day-m-class.jpg`;
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/images/classes/race-license-class.jpg`;
const reacelicenseschool =
	`${settings.IMAGES_BASE_URL}/images/classes/advanced-m-school-banner%201.png`;
const onedaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/one-day-m-class.jpg`;
const m4gt4school =
	`${settings.IMAGES_BASE_URL}/images/classes/m4-gt4-class.jpg`;
const video =
	`${settings.IMAGES_BASE_URL}/videos/indy-home.mp4`;
const raceClock =
	`${settings.IMAGES_BASE_URL}/images/indy_images/race-clock.jpg`;


// other
const skidpad =
	`${settings.IMAGES_BASE_URL}/images/indy_images/skid-pad.jpg`;
const learnline =
	`${settings.IMAGES_BASE_URL}/images/indy_images/02-learn-line.jpg`;
const highspeed =
	`${settings.IMAGES_BASE_URL}/images/indy_images/03-high-speed.jpg`;
const leadfollow =
	`${settings.IMAGES_BASE_URL}/images/indy_images/04-lead-follow.jpg`;
const indyPoster =
	`${settings.IMAGES_BASE_URL}/images/indy_images/indy-bg.jpg`;
const hallowedGround =
	`${settings.IMAGES_BASE_URL}/images/indy_images/hallowed-ground.jpg`;
const mDriversClass = 
  `${settings.IMAGES_BASE_URL}/images/indy_images/m-drivers-class.jpg`;


const BMWM2 =
	`${settings.IMAGES_BASE_URL}/images/indy_images/bmw-m2.jpg`;
const BMWM3 = `${settings.IMAGES_BASE_URL}/images/indy_images/bmw-m3.jpg`;
const BMWM4 = `${settings.IMAGES_BASE_URL}/images/indy_images/bmw-m4.jpg`;

const Agenda = () => {
	return (
		<SchedulePopup
			btnText="View Full-day Agenda"
			title="FULL-DAY M INTENSIVE AT INDY"
			subtitle="Sample schedule subject to change."
		>
			<div className="schedule__item">
				<p className="schedule__item__time">8:00 am - 8:15 am</p>
				<p className="schedule__item__event">Arrival, registration</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">8:30 am - 9:15 am</p>
				<p className="schedule__item__event">Class discussion</p>
			</div>

			<div className="schedule__item">
				<p className="schedule__item__time">9:30 am - 12:00 pm</p>
				<p className="schedule__item__event">
					Group Rotation of three morning events: <br />
					Rotation 1- Track section 1 <br />
					Rotation 2 - Track section 2 <br />
					Rotation 3 - Skid Pad
				</p>
			</div>

			<div className="schedule__item">
				<p className="schedule__item__time">12:00 pm - 1:00 pm</p>
				<p className="schedule__item__event">Lunch</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">1:00 pm - 3:30 pm</p>
				<p className="schedule__item__event">
					Lead / Follow Session 1/ Vehicle 1 <br />
					Lead / Follow Session 2/Vehicle 2 <br />
					Timed Autocross
				</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">3:30 pm - 4:30 pm</p>
				<p className="schedule__item__event">Instructor Hot Laps</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">4:30 pm - 5:00 pm </p>
				<p className="schedule__item__event">Wrap up and farewell</p>
			</div>
		</SchedulePopup>
	);
};
const IndyFullDay = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};

	const [width, setWidth] = useState(null);
	useEffect(() => {
		setWidth(window.innerWidth);
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [width, setWidth]);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	const learnRef = useRef(null);
	const loactionsRef = useRef(null);
	const powerRef = useRef(null);
	const instructorsRef = useRef(null);
	const classesRef = useRef(null);
	return (
		<Layout>
			<SEO
				title="BMW Indy Full-Day School | BMW Performance Driving School"
				description="Learn to drive a BMW M car on the famous Indianapolis Motor Speedway with a full-day experience at the BMW M Track Series."
				keywords="bmw m school, m driving school, bmw m driving school, bmw m performance driving school, full-day m experience"
			/>
			<div className="indy__school">
        <TopBannerIndy
          url={video}
					image={indyPoster}
					mobileImage={indyPoster}
					header="BMW FULL-DAY M INTENSIVE AT INDY"
					subheader="SPEEDWAY DRIVING SCHOOL"
					// pricing={price + " PER PERSON; TWO PEOPLE PER CAR."}
					text="The Indianapolis Motor Speedway is one of the most famous racetracks in the country, and you’re about to experience it from behind the wheel of a BMW M car. Better bring your A game. See below for details."
					secondText={"Check back soon for more information"}
					buttonText="Book Now"
					butttonURL="https://pds.eventsbmw.com/?brand=M&date=2024-04-01&class=Full_Day_M_Intensive_at_INDY&_ga=2.86427920.962657958.1700483800-1248070579.1677079354#"
					agendaPopup={<Agenda />}
          ariaLabel="indy-full-day"
				/>
				<section className="links__indy">
					<div>
						<ul>
							<li>
								<button
									onClick={() => {
										scrollToView(learnRef);
									}}
								>
									What you’ll learn
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(loactionsRef);
									}}
								>
									Locations
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(powerRef);
									}}
								>
									What you’ll drive
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(instructorsRef);
									}}
								>
									Pro instructors
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(classesRef);
									}}
								>
									Other classes
								</button>
							</li>
						</ul>
					</div>
				</section>
				<section ref={learnRef} className="learn__indy">
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Skid Pad">
								<LazyLoadImage effect="blur" src={skidpad} alt="Skip Pad" />
								<div className="accordion-content">
									<h3>Skid Pad</h3>
									<p>
										Feel the difference between under- and oversteer, and learn
										to control the back of the car with throttle. Get it right,
										and you’ll start to glide sideways with ease.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Learn the Line">
								<LazyLoadImage
									effect="blur"
									src={learnline}
									alt="Learn the Line"
								/>
								<div className="accordion-content">
									<h3>Technical Perfection</h3>
									<p>
										A perfect lap starts with where you look. We’ll break down
										Indy into two sections and guide you through proper brake
										and turn-in points. Look as far ahead as you can; those
										corners come fast.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="High-Speed Track">
								<LazyLoadImage
									effect="blur"
									src={highspeed}
									alt="High-Speed Track"
								/>
								<div className="accordion-content">
									<h3>Game time</h3>
									<p>
										Indy offers the thrill of a full-on race course, and you’ll
										experience every high-speed turn. Remember all your brake
										points and when to go flat out, and you’ll soon be lapping
										faster than you thought possible.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Lead/Follow">
								<LazyLoadImage
									effect="blur"
									src={leadfollow}
									alt="Lead/Follow"
								/>
								<div className="accordion-content">
									<h3>Follow the leader</h3>
									<p>
										You go where you look, and here you’ll be looking at our
										lead instructor’s car as they guide you around the proper
										line at ever-increasing speeds.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Timed Autocross">
								<LazyLoadImage
									effect="blur"
									src={raceClock}
									alt="Timed Autocross"
								/>
								<div className="accordion-content">
									<h3>Race the Clock</h3>
									<p>
										You’ll learn a lot here at Indy. Can you put it all together
										for timed runs against the other drivers? Smoothness is the
										key. Win the day and you’ll be Master of M.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__indy__images">
									<TabContent for="skip-pad" uniqueKey="1">
										<img src={skidpad} alt="Skip Pad" />
									</TabContent>
									<TabContent for="learn-line" uniqueKey="2">
										<img
											src={learnline}
											alt="Learn the Line"
										/>
									</TabContent>
									<TabContent for="high-speed" uniqueKey="3">
										<img
											src={highspeed}
											alt="High Speed Track"
										/>
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="4">
										<img
											src={leadfollow}
											alt="Lead Follow"
										/>
									</TabContent>
									<TabContent for="time-autocross" uniqueKey="5">
										<img
											src={raceClock}
											alt="Timed Autocross"
										/>
									</TabContent>
								</div>
								<ul className="learn__indy__menu" role="tablist">
									<li role="none">
										<TabLink to="skip-pad" uniqueKey="7">Skid Pad</TabLink>
									</li>
									<li role="none">
										<TabLink to="learn-line" uniqueKey="8">Learn the Line</TabLink>
									</li>
									<li role="none">
										<TabLink to="high-speed" uniqueKey="9">High-speed track</TabLink>
									</li>
									<li role="none">
										<TabLink to="lead-follow" uniqueKey="10">Lead/Follow</TabLink>
									</li>
									<li role="none">
										<TabLink to="time-autocross" uniqueKey="11">Timed Autocross</TabLink>
									</li>
								</ul>
								<div className="learn__indy__content">
									<TabContent for="skip-pad" uniqueKey="13">
										<h3>Skid Pad</h3>
										<p>
											Feel the difference between under- and oversteer, and
											learn to control the back of the car with throttle. Get it
											right, and you’ll start to glide sideways with ease.
										</p>
									</TabContent>
									<TabContent for="learn-line" uniqueKey="14">
										<h3>Technical Perfection</h3>
										<p>
											A perfect lap starts with where you look. We’ll break down
											Indy into two sections and guide you through proper brake
											and turn-in points. Look as far ahead as you can; those
											corners come fast.
										</p>
									</TabContent>
									<TabContent for="high-speed" uniqueKey="15">
										<h3>Game time</h3>
										<p>
											Indy offers the thrill of a full-on race course, and
											you’ll experience every high-speed turn. Remember all your
											brake points and when to go flat out, and you’ll soon be
											lapping faster than you thought possible.
										</p>
									</TabContent>
									<TabContent for="lead-follow" uniqueKey="16">
										<h3>Follow the leader</h3>
										<p>
											You go where you look, and here you’ll be looking at our
											lead instructor’s car as they guide you around the proper
											line at ever-increasing speeds.
										</p>
									</TabContent>
									<TabContent for="time-autocross" uniqueKey="17">
										<h3>Race the clock</h3>
										<p>
											You’ll learn a lot here at Indy. Can you put it all
											together for timed runs against the other drivers?
											Smoothness is the key. Win the day and you’ll be Master of
											M.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<section
					ref={loactionsRef}
					className="locations__indy"
					style={{backgroundImage: `url(${hallowedGround})`,}}
				>
					<div className="container">
						<div className="locations__indy__content">
							<h3>Hallowed ground</h3>
							<h4>Indianapolis Motor Speedway</h4>
							<p>
								Home of so many iconic races, the Indianapolis Motor Speedway is
								the perfect backdrop to hone your driving skills. You’ll see
								some serious speed here.
							</p>
						</div>
					</div>
				</section>
				<section ref={powerRef} className="power__indy">
					<div className="container">
						<div className="striped-background">
							<div className="blue-stripe" />
							<div className="darkblue-stripe" />
							<div className="red-stripe" />
						</div>
						<div className="power__indy__header">
							<h2>POWER. POISE. PRECISION.</h2>
							<p>
                Each M car offers the same core of performance while bringing something unique to the table. 
                Experience them all during your speedway driving experience.
							</p>
              <p className="note">*Vehicles based on availability.</p>
						</div>
					</div>
					<div className="power__indy__content">
						<div className="row">
							<div className="power__indy__item">
								<LazyLoadImage
									effect="blur"
									src={BMWM2}
									alt="The BMW M2 Competition"
								/>
								<h3>The BMW M2</h3>
								<p>
                  The mighty M2 is our autocross king, and its agile handling abilities will have you smiling 
                  from ear to ear.
								</p>
							</div>
							<div className="power__indy__item">
								<LazyLoadImage effect="blur" src={BMWM3} alt="The BMW M3 Competition" />
								<h3>The BMW M3 Competition</h3>
								<p>
                  Four doors just means more fun. The BMW M3 will prove to you why its iconic name is revered 
                  throughout the motorsport world.
								</p>
							</div>
							<div className="power__indy__item">
								<LazyLoadImage
									effect="blur"
									src={BMWM4}
									alt="The BMW M4 Competition"
								/>
								<h3>The BMW M4 Competition</h3>
								<p>
                  A perfect balance of precision and power, the BMW M4 is an ace on the track with 503 horsepower 
                  on tap.
								</p>
							</div>
						</div>
					</div>
				</section>
				<ProInstructors ref={instructorsRef} />
				<section ref={classesRef} className="other__schools__indy">
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other__schools__indy__content row">
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool" aria-label="twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/advancedmschool" aria-label="advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={reacelicenseschool}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>Feel the need for real speed.</p>
							<Link to="/mschool/racelicenseschool" aria-label="racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt=">One-Day M School"
							/>
							<h3>One-Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool" aria-label="onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={m4gt4school}
								alt="M4 GT4 School"
							/>
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4" aria-label="m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other__schools__indy__item">
							<LazyLoadImage
								effect="blur"
								src={mDriversClass}
								alt="M4 GT4 School"
							/>
							<h3>M Driver’s Program</h3>
							<p>Take yourself to the limit.</p>
							<Link to="/mschool/mdriver" aria-label="m-driver">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
        < BmwPcLocations page="full-day" />
			</div>
		</Layout>
	);
};

export default IndyFullDay;
